
nav{
    display: flex;
    width: 100%;
    border-bottom: 2px solid #f3f3f3;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    z-index: 999;
}

/*  Start of Right Side Styling  */

.rightSide{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: start;
}
.rightSide>h1{
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 1rem;
}

/*  End of Right Side Styling  */



/*  Start of Left Side Styling  */

.leftSide{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5rem;
    flex: 4;
}


input{
    padding: 12px 20px;
    border: none;
    background: #f7f6f6;
    outline: none;
    border-radius: 5px;
    width: 14rem;
} 

input:focus{
    border: 2px solid #2196f3;
}


.navIcon{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 2rem;
    color: #666565;
}

.navIcon:hover{
    color: #2196f3;
}


/* Tablet Responsive................. */

@media (max-width: 820px){

    .rightSide{
        flex: 1;

    }
    .rightSide>h1{
        font-size: 1.3rem;
    }

    .leftSide{
        flex: 2;
        justify-content: flex-end;
        padding-right: 0rem;

    }
}


/* Mobile responsive........................................ */

@media (max-width: 415px) {

    .rightSide{
        flex: 1;
    }

    .leftSide{
        flex: 1;
        justify-content: flex-end;
        padding-right: 20px;    

}

    .Search{
        display: none;
    }

    .rightSide>h1{
        font-size: 1rem;
        margin-left: 0;
    }

    .navIcons{
        margin: 0;
        padding: 0;
        display: flex;
     }

     .navIcon{
        margin-left: 0.5rem;
     }

}


/*  End of Right Side Styling  */
