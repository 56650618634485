   .cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
    border-top: 2px solid #e5e5e5;
    padding-top: 1rem;
    padding-bottom: 1rem;
    }

    
    .Footer{
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    
    .Logo{
        display: flex;
        align-items: center;
        gap: 1rem;
    
    }
    
    
    .block{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-width: 12rem;
    }
    
    .detail{
        display: flex;
        flex-direction: column;
        width: inherit;
        font-size: 0.9rem;
        gap: 1rem;
        font-style: italic;
    }
    
    .detail>span:nth-child(1){
        font-weight: 500;
        font-style: normal;
        font-size: 1.2rem;
    }
    
    .pngLine:hover{
        cursor: pointer;
        color: #2196f3;
    }

    
    .pngLine{
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    
    .icon{
      font-size: 1.8rem;    
    }



    .Footer2{
    
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }
    

/* Tablet Responsive ................... */

@media screen and (max-width: 820px){

    .cFooterWrapper{
        padding: 1rem 2rem;
    }

    .cFooterWrapper>:first-child{
        margin-bottom: 3rem;
    }

    .Footer{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

    }

    .Logo{
        width: 50%;
    }


}

/* Mobile Responsive .................... */


@media screen and (max-width: 415px){


    .cFooterWrapper>:first-child{
        margin-bottom: 3rem;
        
    }

    .Logo{
        display: none;
    }

    .Footer{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem 0rem;
        justify-content: space-between;
        padding: 0px;
    }


    .detail>:first-child{
        text-align: center;
    }


    .pngLine{
        margin-left: 1.5rem;
    }


} 