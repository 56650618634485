
.body{
    display: flex;
    width: 100%;
}

.leftSide{
    flex: 1;
}

.rightSide{
    flex: 4;
}


/* Tablet Responsive ..................... */


@media  (max-width:820px) {

    .leftSide{
        flex: 1;
    }

    .rightSide{
        flex: 2;
    }
}




/* mobile responsive .................. */

@media (max-width: 415px){

    .body{
        padding: 0px 20px;

    }

    .rightSide{
        display:flex ;
        flex-direction: column;
    }
    .leftSide.inactive{
        opacity: 0;
        display: none;
    }

    .leftSide.active{
        opacity: 1;
        display: block;
        position: absolute;
        left: 0.5rem;
        top: 8rem;
        background: rgb(128, 140, 243);
        z-index: 999;
        padding: 30px;
        border-radius: 1rem;
        border: none;
        
    }

}


