
.card-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    z-index: 2;
}


/* Start of Recomended Section Styles */

.recomended-section{
    margin-top: 1rem;
}

/* .recomended-section>:first-child{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 5rem;
} */

.recomended-section>div{
    position: relative;
}

.recomended-section>:first-child>:nth-child(2){

    display: none;
}

.recomended-section>:first-child>:nth-child(2)>span{
    font-size: 1rem;
    margin-right: 10px;
}

.recommended-title{
    margin-bottom:1rem ;
}

.filter-boundel{
    display: none;
}

/* btn styles */

.btn{
    padding: 10px 15px;
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 1rem;
    cursor: pointer;
}

.btn:hover{
    background-color: #2196f3;
    color: white;
}

/* End of Recomended Section Styles */



/* Start of Singel Cards Styles */

.card{
    display: flex;
    flex-direction: column;
    align-items: space-around;
    width: 20rem;
    justify-content: center;
    margin: 1rem;
    border: 2px solid #ededed;
    padding: 20px;
    cursor: pointer;
    gap: 1rem;
}

.card:hover{
    border: 2px solid #2196f3 ;
    border-radius: 1rem;
}


.card>img{
    width: 10rem;
    margin-bottom: 1rem;
    margin: auto;
}

.card-title{
    margin: auto;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.card-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


/* start of card reviews section */

.card-reviews{
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rating-stars{
    color: #d5ab55;
}

/* End of card reviews section */


/* Start of card Price section */

.total-reviews{
    font-size: 0.9rem;
    margin-left: 1rem;
}

.price{
    font-size: 0.9rem;
    font-weight: bold;
}

.price>:first-child{
    margin-right: 0.8rem;
    text-decoration: line-through;
}

.card-price{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.bag-icon{
    color: #535353;
    font-size: 1.3rem;
}
.bag-icon:hover{
    color: #2196f3;
}

/* End of card Price section */



/* Tablet Responsive ....................... */

@media (max-width: 820px){

    .recommended-title{
        font-size:1.3rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    .btns{
        display: flex;
        justify-content: center;
    }
    .card-container{
        
        display: flex;
        justify-content: center;
    }


}


/* Mobile Responsive ...................... */

@media (max-width:415px) {


    .recommended-title{

        font-size:1rem;
        text-align: center;
    }

    .btns{
        display: grid;
        grid: 1fr/ auto auto auto;
        gap: 20px 10px;
    }

    .card{
        margin: 0.5rem;
    }

    .recomended-section>:first-child>:nth-child(2){
        position: absolute;
        top: 0px;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        display: flex;
    }

}

