.sidebar{
    width: 90%;
    height: 100%;
    border-right:2px solid #e5e5e5 ;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.sidebarSections{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    
}

.categoryTitle{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}


.sidebar-title{
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 2rem;
}



.sidebarLabelContainer{
    position: relative;
    padding-left: 2rem;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.sidebarLabelContainer>input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.Checkmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;

}

.sidebarLabelContainer:hover>input ~ .Checkmark {
    background-color: #ccc;
}

.sidebarLabelContainer>input:checked ~ .Checkmark {
    background-color: #2196f3;
}

.Checkmark ::after{
    content: "";
    position: absolute;
    display: none;
}


.sidebarLabelContainer>input:checked ~ .Checkmark::after{
    display: block;

}

.sidebarLabelContainer>.Checkmark::after{
    top: 64px;
    left: 64px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: white;
}


/* Tablet Responsive ................. */

@media (max-width:768px){
    .categoryTitle{
        font-size: 1.3rem ;
    }
}



/* Mobile Responsive ................. */

@media (max-width:415px) {

    .categoryTitle{

        font-size:1rem;
    }

    .sidebar.active{
        border: none;
        width: 10rem ;
    }


}